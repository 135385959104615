// layout
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
a {
  @include transition($link-transition);
  font-weight: $link-weight;
}
.wrap {
  padding-bottom: $grid-gutter-height*2;
  @include media-breakpoint-up(lg) {
    padding-bottom: $grid-gutter-height*3;
  }
  .main {
    margin-bottom: $grid-gutter-height*1.5;
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-height*2;
    }
  }
}
// type
p,ol,ul,blockquote,.wp-block-button,.wp-block-embed {
  margin-bottom: 1.25rem;
}
h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-bottom: .75em;
  letter-spacing: -0.03em;
}
h1,.h1 {
  font-weight: $font-weight-normal;
  font-family: $font-family-serif;
  letter-spacing: -0.03em;
  margin-bottom: .25em;
  @include media-breakpoint-up(md) {
    margin-bottom: 1em;
  }
}
h2,.h2 {
  font-weight: $font-weight-med;
  letter-spacing: -0.02em;
}
h4,.h4 {
  @extend .condensed-caps;
}
.social {
  @extend .list-normalize;
  li {
    display: inline-block;
    margin: 0 $grid-gutter-width/3;
    a {
      display: block;
    }
  }
}

.page-header {
  text-align: center;
  margin: $grid-gutter-height*2 0 $grid-gutter-height;
  @include media-breakpoint-up(md) {
    margin: $grid-gutter-height*3 0 $grid-gutter-height*2;
  }
  .byline,time {
    display: block;
    margin-bottom: 0;
    font-size: $font-size-sm;
  }
  .single-post & {
    margin-bottom: $grid-gutter-height*2;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-height*3;
    }
    h1 {
      margin-bottom: .3em;
    }
  }
  .tagged {
    em {
      @extend .condensed-caps;
      font-style: normal;
    }
  }
}
.introtext {
  text-align: center;
  padding-bottom: $grid-gutter-height;
  @include media-breakpoint-up(md) {
    padding: 0 $grid-gutter-width*2.5 $grid-gutter-height;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 $grid-gutter-width*3 $grid-gutter-height;
  }
}

// WP overrides
.wp-block-button__link {
  @extend .btn;
}
.wp-block-separator {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
}
.wp-block-image {
  margin-bottom: $grid-gutter-height;
  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-height*1.5;
  }
  img {
    @extend .img-fluid;
  }
}
.wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0; width:
    100%;
    height: 100%;
  }
}
