footer.content-info {
  @extend .bg-white;
  font-size: $font-size-sm;
  border-top: 1px solid $gray;
  padding-top: $grid-gutter-width ;
  a {
    font-weight: $font-weight-base;
    color: $black;
    text-decoration: none;
    @include hover-focus {
      color: $link-color;
    }
  }
  .brand {
    max-width: $grid-gutter-height*2.8;
    margin-bottom: $grid-gutter-height;
    display: block;
    img {
      width: 100%;
    }
  }
  .adr {
    p {
      margin-bottom: $grid-gutter-height*1.25;
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-height*2;
      }
    }
  }
  .contacts {
    @extend .list-normalize;
    li {
      margin: 0 0 $grid-gutter-width*.5;
      @include media-breakpoint-up(lg) {
        display: inline-block;
        margin: 0 0 0 $grid-gutter-width*1.5;
        &:first-child {
          margin-left: 0;
        }
      }
      i {
        font-size: $font-size-md;
        margin-right: 5px;
      }
    }
  }
  .footer-nav {
    @include media-breakpoint-down(sm) {
      margin-top: $grid-gutter-height;
    }
    h4 {
      margin-bottom: $grid-gutter-height;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-height/2;
      }
    }
    ul {
      @extend .list-normalize;
      margin-bottom: $grid-gutter-height;
      li {
        margin-bottom: $grid-gutter-height*.4;
        line-height: 1.3;
      }
    }
  }
  .socials {
    h4 {
      margin: $grid-gutter-height 0;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-height/2;
      }
    }
    .social {
      li {
        margin: 0 5px 0 0;
        @include media-breakpoint-only(lg) {
          margin-right: -1px;
//           margin-right: $grid-gutter-width/5;
        }
        a {
          font-size: $font-size-md;
          height: $grid-gutter-height*2;
          width: $grid-gutter-height*2;
          border-radius: 50%;
          background-color: $gray;
          line-height: $grid-gutter-height*2;
          text-align: center;
          @include hover-focus {
            background-color: $brand-primary;
            color: $white;
          }
        }
      }
    }
  }
  .copyright {
    @extend .bg-dark;
    margin: $grid-gutter-width 0 0;
    padding: $grid-gutter-width/3 $grid-gutter-width/2;
    text-align:center;
    font-size: $font-size-xs;
    letter-spacing: 0.02em;
  }
}
