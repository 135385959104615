.condensed-caps {
  font-family: $font-family-condensed;
  font-weight: $font-weight-bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.bg-white {
  background-color: $white;
}
.bg-gray {
  background-color: $gray;
}
.bg-gray-light {
  background-color: $gray-light;
}
.bg-dark {
  background-color: $body-color;
  color: $white;
}
.bg-primary {
  background-color: $brand-primary;
  color: $white;
}
.bg-secondary {
  background-color: $brand-secondary;
  color: $white;
}
.list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
