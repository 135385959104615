.wsp-container {
  margin: $grid-gutter-height*2 0;
  .wsp-pages-title {
    @extend .sr-only;
  }
  .wsp-pages-list {
    @extend .list-normalize;
    a {
      color: $body-color;
      @include hover-focus {
        color: $link-color;
      }
    }
    &>li {
      font-size: $font-size-lg;
      margin-bottom: $grid-gutter-height/2;
      ul.children {
        @extend .list-normalize;
        font-size: $font-size-base;
        margin: $grid-gutter-height/2 0 0 $grid-gutter-width;
        li {
          margin-bottom: $grid-gutter-height/5;
          a {
            font-weight: $font-weight-base;
          }
        }
      }
    }
  }
}
