// Grid settings - basic
$grid-gutter-width-base:  30px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      20px;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$blue:        #249CF2;
$red:         #F34B4B;
$white:       #fff;
$black:       #5C6166;
$gray:        #E1E3E5;
$gray-light:  #F7F7F7;
$gray-dark:   #AAAEB2;

$brand-primary:   $blue;
$brand-secondary: $red;
$brand-success:   $blue;
$brand-danger:    $red;

$body-bg:         $gray-light;
$body-color:      $black;


// Links
$link-color:            $brand-primary;
$link-decoration:       none;
$link-weight:           700;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;

// Fonts
$font-family-sans:      'Asap', "Helvetica Neue", Arial, sans-serif;
$font-family-condensed: 'Asap Condensed', "Helvetica Neue", Arial, sans-serif;
$font-family-serif:     'Crimson Text', serif;
$font-family-monospace: 'IBM Plex Mono',Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:      $font-family-sans;

$font-weight-normal:    400;
$font-weight-med:       500;
$font-weight-semi:      600;
$font-weight-bold:      700;
$font-weight-base:      $font-weight-med;

$font-size-base: 1rem;     // 16px
$font-size-lg:   1.5rem;   // 24px
$font-size-md:   1.125rem; // 18px
$font-size-sm:   .875rem;  // 14px
$font-size-xs:   .75rem;   // 12px
$font-size-2xs:  .6875rem; // 11px

$font-size-h1:   2.5rem;   // 40px
$font-size-h2:   2rem;     // 32px
$font-size-h3:   1.5rem;   // 24px
$font-size-h4:   1rem;     // 16px
$font-size-h5:   1rem;     // 16px
$font-size-h6:   .75rem;   // 12px

$headings-font-family:  $font-family-sans;
$headings-font-weight:  $font-weight-semi;
$headings-line-height:  1.2;
$headings-color:        inherit;

$border-radius:          4px;
$border-radius-lg:       10px;
$border-radius-sm:       $border-radius;


// Buttons
$btn-padding-x:                  $grid-gutter-width;
$btn-padding-y:                  $grid-gutter-width/3;
$btn-line-height:                1;
$btn-font-size:                  $font-size-md;

$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;
$btn-primary-hover-color:        $white;
$btn-primary-hover-bg:           $body-color;
$btn-primary-hover-border:       $body-color;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-border:           $brand-secondary;
$btn-secondary-hover-color:      $white;
$btn-secondary-hover-bg:         $body-color;
$btn-secondary-hover-border:     $body-color;

$btn-white-color:                $brand-primary;
$btn-white-bg:                   $white;
$btn-white-border:               $white;
$btn-white-hover-color:          $white;
$btn-white-hover-bg:             $body-color;
$btn-white-hover-border:         $body-color;

$btn-white-alt-color:            $brand-secondary;

$btn-dark-color:                 $white;
$btn-dark-bg:                    $body-color;
$btn-dark-border:                $body-color;
$btn-dark-hover-color:           $white;
$btn-dark-hover-bg:              $brand-primary;
$btn-dark-hover-border:          $brand-primary;

$btn-trans-color:                $body-color;
$btn-trans-bg:                   transparent;
$btn-trans-border:               $body-color;
$btn-trans-hover-color:          $body-color;
$btn-trans-hover-bg:             $gray;
$btn-trans-hover-border:         $body-color;

$btn-trans-white-color:          $white;
$btn-trans-white-bg:             transparent;
$btn-trans-white-border:         $white;
$btn-trans-white-hover-color:    $white;
$btn-trans-white-hover-bg:       $body-color;
$btn-trans-white-hover-border:   $body-color;

// Forms

$input-padding-x:                1rem;
$input-padding-y:                .625rem;
$input-line-height:              1;

$input-bg:                       $gray-light;
$input-bg-disabled:              $gray;

$input-color:                    $black;
$input-border-color:             $gray;
$input-btn-border-width:         1px; // For form controls and buttons
$input-box-shadow:               none;

$input-border-radius:            4px;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-bg-focus:                 $input-bg;
$input-border-focus:             lighten($brand-primary, 25%);
$input-box-shadow-focus:         none;
$input-color-focus:              $input-color;

$input-color-placeholder:        rgba($black,.5);

$input-transition:               border-color ease-in-out .15s;
