header.banner {
  @extend .bg-white;
  border-bottom: 1px solid $gray;
  .social {
    li {
      a {
        color: $white;
      }
    }
  }
  .ancillary {
    @extend .bg-dark;
    font-size: $font-size-xs;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-xs;
    }
    letter-spacing: 0.02em;
    padding: $grid-gutter-width/3 0;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width/5 0;
    }
    .row {
      align-items: center;
      &>div {
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
          a {
            text-decoration: underline;
            font-weight: $font-weight-base;
          }
        }
      }
    }
    a {
      color: $white;
      transition: $link-transition;
      @include hover-focus {
        color: $gray;
      }
    }
    p {
      margin: 0;
    }
    .social {
      li {
        margin: 0 $grid-gutter-width/4 0 0;
        @include media-breakpoint-up(lg) {
          margin-right: $grid-gutter-width/2;
        }
        a {
          font-size: $font-size-xs;
          @include media-breakpoint-up(lg) {
            font-size: $font-size-md;
          }
        }
      }
    }
  }
  .primary-items {
    &>.row {
      align-items: center;
      &>div {
        &.toggle-col {
          padding: 0;
          text-align: right;
        }
        &.cta-col {
          text-align: right;
          position: relative;
          padding-right: 65px;
          .btn {
            @extend .btn-secondary;
          }
          .search-form {
            position: absolute;
            top: 0;
            right:$grid-gutter-width/2;
            input[type=search] {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background: $gray url(../images/search.png) no-repeat 100% 50%;
              background-size: 28px;
              color: transparent;
              border: none;
            	transition: all .5s;
            	font: $font-size-base $font-family-base;
            	&:focus {
              	width: 185px;
              	background-color: $gray-light;
              	padding-right: 40px;
                color: $body-color;
            	}
            }
          }
        }
      }
    }
  }
  .navbar-toggler {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    margin: 0 $grid-gutter-width/2 0 0;
    background-color: $gray;
    color: $black;
    font-size: $font-size-md;
    text-align: center;
    outline: none;
    .fa-bars {
      display: none;
    }
    &.ready,&.collapsed {
      .fa-bars {
        display: inline-block;
      }
      .fa-times {
        display: none;
      }
    }
  }
  .brand {
    display: block;
    padding: 10px 0;
    max-width: 56px;
    @include media-breakpoint-up(lg) {
      padding: 12px 0;
      max-width: none;
    }
    img {
      width: 100%;
    }
  }
  .nav-primary {
    .nav {
      .nav-item {
        .nav-link {
          @extend .condensed-caps;
          color: $black;
          @include media-breakpoint-only(lg) {
            font-size: 15px;
            padding: .5em .75em;
          }
          @include hover-focus {
            color: $link-color;
          }
        }
        &.active,&.current-page-ancestor,&.current_page_parent {
          .nav-link {
            color: $link-color;
          }
        }
        &.dropdown {
          .dropdown-toggle {
            &:after {
              display: none;
            }
          }
          &:hover {
            &>.dropdown-menu {
              display: block;
            }
          }
          .dropdown-menu {
            @include transition($link-transition);
            min-width: $grid-gutter-height*10;
            width: $grid-gutter-height*10;
            left: 50%;
            transform: translateX(-50%);
            padding: $grid-gutter-width/2 0;
            margin: 0 0 0 -$grid-gutter-height*5;
            background-color: rgba($white,.95);
            border: none;
            border-radius: 5px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            text-align: center;
            @include media-breakpoint-only(lg) {
              font-size: 15px;
            }
            .dropdown-item {
              @extend .condensed-caps;
              word-wrap: break-word;
              white-space: pre-wrap;
              line-height: 1.15;
              color: $black;
              padding: $grid-gutter-width/2 $grid-gutter-width/2;
              font-weight: $font-weight-base;
              @include hover-focus {
                color: $link-color;
                background-color: transparent;
              }
              &.current_page_item,&.active {
                color: $link-color;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  #navbarResponsive {
    @extend .bg-primary;
    padding: 0;
    text-align: center;
    .nav {
      padding: $grid-gutter-width 0 $grid-gutter-width/2;
      .nav-item {
        margin-bottom: $grid-gutter-height/2;
        .nav-link {
          @extend .condensed-caps;
          color: $white;
          padding: $grid-gutter-width*.25 $grid-gutter-width*.5;
          line-height: 1;
          @include hover-focus {
            color: $gray-light;
          }
        }
        &.active {
          .nav-link {
            color: $white;
            text-decoration: underline;
          }
        }
        &.dropdown {
          .dropdown-toggle {
//             padding-bottom: $grid-gutter-width*.25;
            &:after {
              display: none;
            }
          }
          .dropdown-menu {
            display: block;
            position: relative;
            width: 100%;
            background-color: transparent;
            text-align: center;
            border: none;
            margin-top: 0;
            padding-top: 0;
            .dropdown-item {
              font-size: $font-size-xs;
              font-weight: $font-weight-base;
              display: inline-block;
              width: auto;
              color: $white;
              padding: 1px $grid-gutter-height/2;
              @include hover-focus {
                color: $gray-light;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .btn {
      @extend .btn-white;
      margin: 0 auto $grid-gutter-width;
      display: inline-block;
      @include media-breakpoint-only(md) {
        display: none;
      }
    }
    ul.social {
      padding-bottom: $grid-gutter-width*1.5;
      li {
        a {
          height: $grid-gutter-height*2;
          width: $grid-gutter-height*2;
          border-radius: 50%;
          border: 1px solid $white;
          line-height: $grid-gutter-height*2;
          text-align: center;
          @include hover-focus {
            background-color: $white;
            color: $brand-primary;
          }
        }
      }
    }
    .search-form {
      input[type=search] {
        width: 300px;
/*
        width: 40px;
        height: 40px;
*/
        border-radius: 20px;
        background: $gray url(../images/search.png) no-repeat 100% 50%;
        background-size: 28px;
//         color: transparent;
        border: none;
      	transition: all .5s;
      	font: $font-size-base $font-family-base;
      	margin: 0 auto $grid-gutter-width;
/*
      	&:focus {
        	width: 185px;
        	background-color: $gray-light;
        	padding-right: 40px;
          color: $body-color;
      	}
*/
      }
    }
    @include media-breakpoint-down(md) {
      &.collapse {
        display: none !important;
        &.show,&.in {
          display: block !important;
        }
      }
    }
  }
}
