.comments {
  @extend .bg-gray;
  padding: $grid-gutter-width;
  border-radius: 10px;
  font-size: $font-size-sm;
}

.comment-list {
  @extend .list-unstyled;
  margin-bottom: $grid-gutter-width;
  article {
    margin-bottom: $grid-gutter-height;
    .comment-meta {
      .comment-author {
        margin-bottom: $grid-gutter-height/2;
      }
      .comment-metadata {
        a {
          color: $body-color;
//           font-weight: $font-weight-base;
          font-size: $font-size-xs;
        }

      }
    }
    .comment-content {
      p {
        margin-bottom: .75rem;
      }
    }
    .comment-reply-link {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-white;

    }
  }
}
.comment-list ol {
  list-style: none;
}
.comment-form {
  p {
    @extend .form-group;
    &:not(.comment-form-cookies-consent) {
      label {
        @extend .label-style;
        margin-bottom: 0;
      }
    }
    &.comment-form-cookies-consent {
      input[type="checkbox"] {
        margin-right: $grid-gutter-height/2;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="url"],
  textarea {
    @extend .form-control;
    border-color: $gray-dark;
  }
  input[type="submit"] {
    @extend .btn;
  }
}
