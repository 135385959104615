h1.tiles-title {
  text-align: center;
}
.tiles {
  margin-bottom: $grid-gutter-height;
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-height*2;
  }
  .tile {
    margin-bottom: $grid-gutter-width;
    article {
      height: 100%;
      text-align: center;
      figure {
        margin: 0 0 $grid-gutter-height;
        @extend .bg-gray;
        text-align: center;
        a {
          img {
            @extend .img-fluid;
            @include transition($link-transition);
          }
          @include hover-focus {
            img {
              opacity: .95;
            }
          }
        }
      }
      header {
        padding: 0 $grid-gutter-height/5 $grid-gutter-height/2;
        .categories {
          font-size: $font-size-xs;
          margin-bottom: 1em;
          line-height: 1.1;
          color: $gray-dark;
          a {
            color: $gray-dark;
            font-weight: $font-weight-base;
            text-decoration: none;
            @include hover-focus {
              color: $link-color;
            }
          }
        }
      }
      .entry-title {
        margin-bottom: $grid-gutter-height/2;
        a {
          color: $body-color;
          text-decoration: none;
          font-weight: $font-weight-base;
          @include hover-focus {
            color: $link-color;
          }
        }
      }
      .entry-summary {
        padding: 0 $grid-gutter-height/5 $grid-gutter-height;
        p {
          margin-bottom: 0;
        }
      }
      time {
        padding: 0 $grid-gutter-height/5 $grid-gutter-width/2;
        display: block;
        font-size: $font-size-xs;
        color: $gray-dark;
      }
      .entry-action {
        padding: 0 $grid-gutter-height/5 $grid-gutter-height;
      }
    }
  }
  &.article-tiles,&.featured-tiles {
    margin-bottom: 0;
    .tile {
      article {
        text-align: left;
        @extend .bg-white;
        figure {
          margin: 0 0 $grid-gutter-height/2;
        }
        header {
          padding: 0 $grid-gutter-height $grid-gutter-height/2;
          .entry-title {
            margin-bottom: 0;
          }
        }
        .entry-summary {
          padding: 0 $grid-gutter-height $grid-gutter-height;
        }
        time {
          padding: 0 $grid-gutter-height $grid-gutter-width/2;
        }
      }
    }
  }
}
.featured-tiles {
  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-height*.75 !important;
  }
  .tile {
    article {
      @include media-breakpoint-up(md) {
        .details {
          padding-top: $grid-gutter-height;
          padding-bottom: $grid-gutter-height;
        }
      }
      @include media-breakpoint-up(lg) {
        .img {
          padding-right: 0;
          figure {
            margin-bottom: 0;
          }
        }
        .details {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-left: 0;
          header,.entry-summary,time {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
          }
          header {
            padding-bottom: $grid-gutter-height;
            .categories {
              margin-bottom: $grid-gutter-height*1.25;
            }
          }
          .entry-summary {
            flex-grow: 1;
          }
          time {
            padding-bottom: 0;
          }
        }
      }
      @include media-breakpoint-up(xl) {
        .details {
          padding-top: $grid-gutter-width;
          header {
            .categories {
              margin-bottom: $grid-gutter-height*2;
            }
          }
        }
      }
    }
  }
}
.event-tile {
  margin-bottom: $grid-gutter-height;
  article {
    @extend .bg-white;
    text-align: left;
    border-radius: 10px;
    padding: $grid-gutter-height $grid-gutter-width;
    h3 {
//       color: $brand-primary;
    }
    .event-data {
      @extend .h4;
      span {
        display: block;
        &.location {
          margin-top: .5em;
        }
      }
    }
    .event-detail {
      font-size: $font-size-sm;
      p {
        margin-bottom: .5em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      h3,.event-data {
        margin-bottom: 0;
      }
    }
  }
  &.hidden {
    display: none;
  }
}
#view-all-events {
  display: block;
  margin: 0 auto;
}
.taxonomy-wrap {
  .taxonomy-opener {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    background-color: $white;
    margin-bottom: $grid-gutter-height;
    padding: $grid-gutter-width/2 $grid-gutter-height*1.25;
    border-radius: 4px;
    .taxonomy-opener {
      display: block;
      color: $body-color;
      font-size: $font-size-lg;
      font-weight: $font-weight-base;
      line-height: 1;
      text-decoration: none;
      i {
        float: right;
        color: $gray;
      }
      @include hover-focus {
        i {
          color: $body-color;
        }
      }
    }
  }
  .taxonomy-display {
    @extend .list-normalize;
    text-align: center;
    margin-bottom: $grid-gutter-height;
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-height*1.5;
    }
    li {
      display: inline-block;
      margin-bottom: $grid-gutter-height/2;
      .btn {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }
      &:first-child {
        margin-top: $grid-gutter-height/2;
      }
      &:last-child {
        margin-bottom: $grid-gutter-height/2;
      }
    }
    @include media-breakpoint-down(sm) {
      text-align: left;
      margin-bottom: 0;
      li {
        display: block;
        margin-bottom: 0;
        .btn {
          display: block;
          width: 100%;
        }
      }
    }
    @include media-breakpoint-up(md) {
      &.collapse {
        display: block !important;
      }
    }
  }
}
.activity-grid {
  .activity-tile {
    margin-bottom: $grid-gutter-height;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width;
    }
    a {
      @extend .bg-white;
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 4px 0px $gray;
      font-size: $font-size-h4;
      font-weight: $font-weight-base;
      color: $body-color;
      text-decoration: none;
      border-radius: 10px;
      height: 100%;
      padding: $grid-gutter-height $grid-gutter-height*1.25;
      line-height: 1.1;
      @include media-breakpoint-up(md) {
        font-size: $font-size-h3;
      }
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-height*1.25 $grid-gutter-height*1.5;
      }
      @include hover-focus {
        color: $link-color;
      }
      i {
        font-size: $font-size-base;
        margin-left: 1em;
      }
      span,i {
        display: flex;
      }
    }
    &.hidden {
      display: none;
    }
  }
}
