// Grid system
// Grid system
.main {
  @include make-col-ready();
  @include make-col(12);
  @include make-col-offset(0);
  body:not(.blog,.archive,.search) & {
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include make-col(12);
  @include make-col-offset(0);
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(3);
  }
}
/*
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
*/
