.home-hero {
  @extend .bg-primary;
  &>.container-fluid {
    &>.row {
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }
      .hero {
        padding: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 340px;
        @include media-breakpoint-up(sm) {
          min-height: 440px;
        }
        @include media-breakpoint-up(md) {
          min-height: 0;
        }
      }
      .content {
        text-align: center;
        padding-top: $grid-gutter-height*2;
        padding-bottom: $grid-gutter-height*2;
        @include media-breakpoint-up(md) {
          padding-right: $grid-gutter-width;
          padding-left: $grid-gutter-width;
        }
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-height*3 $grid-gutter-width*1.5 $grid-gutter-height*3 $grid-gutter-width*.5;
          max-width: 475px;
        }
        @include media-breakpoint-up(xl) {
          padding-right: $grid-gutter-width*2;
          padding-left: $grid-gutter-width/2;
          max-width: 550px;
        }
        h2 {
          @extend .h1;
        }
        h2,h3 {
          margin-bottom: .5em;
          @include media-breakpoint-up(lg) {
            margin-bottom: $grid-gutter-height*1.5;
          }
          @include media-breakpoint-up(xl) {
            margin-bottom: $grid-gutter-height*2;
          }
        }
        .wp-block-button__link {
          @extend .btn-white;
          @include media-breakpoint-up(lg) {
            margin-top: $grid-gutter-height*1.5;
          }
          @include media-breakpoint-up(xl) {
            margin-top: $grid-gutter-height*2;
          }
        }
      }
    }
  }
}
.home-block {
  text-align: center;
  padding: $grid-gutter-height*2 0;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-height*3 0;
  }
  h1 {
    text-align: center;
    margin-bottom: .5em;
    @include media-breakpoint-up(lg) {
      margin-bottom: 1em;
    }
  }
  .img {
    @include media-breakpoint-down(md) {
      margin-bottom: $grid-gutter-height;
    }
  }
  &.whatwedo {
    @extend .bg-white;
    .details {
      text-align: left
    }
  }
  &.whywedoit {
    @include media-breakpoint-up(lg) {
      .row {
        flex-direction: row-reverse;
      }
    }
    .details {
      @include media-breakpoint-down(md) {
        h1 {
          display: none;
        }
      }
    }
  }
  &.whatsnew {
    padding-top: 0;
  }
  &.events {
    padding-top: 0;
  }
  &.impact-stats {
    padding: 0;
    margin-bottom: $grid-gutter-height*2;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-height*3;
    }
    .stats-wrap {
      padding-top: $grid-gutter-height/2;
      padding-bottom: $grid-gutter-height;
      justify-content: center;
      .stat {
        article {
          @extend .bg-secondary;
          display: flex;
          flex: 1;
          align-items: center;
          border-radius: 50%;
          text-align: center;
          font-weight: $font-weight-bold;
          font-size: $font-size-sm;
          line-height: 1.1;
          width: 135px;
          height: 135px;
          padding: $grid-gutter-height/2;
          margin: $grid-gutter-height/2 auto;
          @include media-breakpoint-up(sm) {
            width: 200px;
            height: 200px;
            font-size: $font-size-base;
          }
          @include media-breakpoint-up(md) {
            width: 200px;
            height: 200px;
          }
          @include media-breakpoint-up(lg) {
            width: 200px;
            height: 200px;
            font-size: $font-size-md;
          }
          @include media-breakpoint-up(xl) {
            width: 245px;
            height: 245px;
            font-size: $font-size-lg;
          }
          span {
            width: 100%;
          }
          em {
            display: block;
            font: normal $font-weight-base $font-size-h3 $font-family-serif;
            letter-spacing: -0.03em;
            margin-bottom: .25em;
            @include media-breakpoint-up(sm) {
              font-size: $font-size-h2;
            }
            @include media-breakpoint-up(lg) {
              font-size: $font-size-h2;
            }
            @include media-breakpoint-up(xl) {
              font-size: $font-size-h1;
            }
          }
        }
      }
    }
    .home & {
      @extend .bg-secondary;
      padding: $grid-gutter-height*2 0;
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-height*3 0;
      }
      .stats-wrap {
        .stat {
          article {
            @extend .bg-white;
            color: $brand-secondary;
          }
        }
      }
      .btn {
        @extend .btn-white-red
/*
        @extend .btn-trans;
        @include hover-focus {
          color: $btn-primary-hover-color;
          background-color: $btn-primary-hover-bg;
          border-color: $btn-primary-hover-border;
        }
*/
      }
    }
  }
}
