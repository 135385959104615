.nav-links {
  margin-top: $grid-gutter-width;
  text-align: center;
  @extend .row;
  justify-content: center;
  .nav-previous,.nav-next {
    display: inline-block;
    margin: 0 $grid-gutter-width/2;
    a {
      @extend .btn;
      min-width: 165px;
    }
    @include media-breakpoint-down(sm) {
      margin: 0 $grid-gutter-width/3;
      a {
        padding-left: 5px;
        padding-right: 5px;
        width: 130px;
        min-width: 0;
      }
    }
  }
}
.single-post {
  .entry-content {
    margin-bottom: $grid-gutter-height*2;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-height*3;
    }
    figure.featured-img {
      text-align: center;
      margin-bottom: $grid-gutter-height;
    }
    footer.tags {
      @extend .bg-white;
      border-radius: 10px;
      padding: $grid-gutter-height $grid-gutter-width;
      font-size: $font-size-sm;
      margin-top: $grid-gutter-width*1.5;
      span {
        @extend .condensed-caps;
      }
      a {
        font-weight: $font-weight-base;
      }
    }
  }
}
