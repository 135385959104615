.btn {
  @extend .condensed-caps;
  cursor: pointer;
  border-width: 2px;
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;
  @include hover-focus {
    color: $btn-primary-hover-color;
    background-color: $btn-primary-hover-bg;
    border-color: $btn-primary-hover-border;
    outline: none !important;
    box-shadow: none !important;
  }
  &.btn-secondary {
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg;
    border-color: $btn-secondary-border;
    @include hover-focus {
      color: $btn-secondary-hover-color;
      background-color: $btn-secondary-hover-bg;
      border-color: $btn-secondary-hover-border;
    }
  }
  &.btn-white {
    color: $btn-white-color;
    background-color: $btn-white-bg;
    border-color: $btn-white-border;
    @include hover-focus {
      color: $btn-white-hover-color;
      background-color: $btn-white-hover-bg;
      border-color: $btn-white-hover-border;
    }
  }
  &.btn-white-red {
    color: $brand-secondary;
    background-color: $btn-white-bg;
    border-color: $btn-white-border;
    @include hover-focus {
      color: $btn-white-hover-color;
      background-color: $btn-white-hover-bg;
      border-color: $btn-white-hover-border;
    }
  }
  &.btn-white-alt {
    color: $btn-white-alt-color;
    background-color: $btn-white-bg;
    border-color: $btn-white-border;
    @include hover-focus {
      color: $btn-white-hover-color;
      background-color: $btn-white-hover-bg;
      border-color: $btn-white-hover-border;
    }
  }
  &.btn-dark {
    color: $btn-dark-color;
    background-color: $btn-dark-bg;
    border-color: $btn-dark-border;
    @include hover-focus {
      color: $btn-dark-hover-color;
      background-color: $btn-dark-hover-bg;
      border-color: $btn-dark-hover-border;
    }
  }
  &.btn-trans {
    color: $btn-trans-color;
    background-color: $btn-trans-bg;
    border-color: $btn-trans-border;
    @include hover-focus {
      color: $btn-trans-hover-color;
      background-color: $btn-trans-hover-bg;
      border-color: $btn-trans-hover-border;
    }
  }
  &.btn-trans-all {
    color: $gray-dark;
    background-color: $btn-trans-bg;
    border-color: $btn-trans-bg;
    @include hover-focus {
      color: $link-color;
      background-color: $btn-trans-bg;
      border-color: $btn-trans-bg;
    }
    &.on {
      color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;
      @include hover-focus {
        color: $btn-primary-color;
        background-color: $btn-primary-bg;
        border-color: $btn-primary-border;
      }
    }
  }
  &.btn-trans-white {
    color: $btn-trans-white-color;
    background-color: $btn-trans-white-bg;
    border-color: $btn-trans-white-border;
    @include hover-focus {
      color: $btn-trans-white-hover-color;
      background-color: $btn-trans-white-hover-bg;
      border-color: $btn-trans-white-hover-border;
    }
  }
}
.btn-xs {
  font-size: $font-size-xs;
  padding: 2px 4px;
}
