.block {
  padding: $grid-gutter-width 0;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-height*2 0;
  }
  &.block-subscribe {
    @extend .bg-white;
    text-align: center;
  }
  &.block-subscribe-blog {
    @extend .bg-white;
    text-align: center;
    border-top: 1px solid $gray;
//     padding-top: 0;
  }
  &.block-action-links {
    padding-bottom: 0;
    justify-content: center;
    h3 {
      @extend .condensed-caps;
      margin-bottom: 0;
      text-align: center;
    }
    .action-link {
      margin-top: $grid-gutter-height;
      .btn {
        width: 100%;
        padding-left: $grid-gutter-height/4;
        padding-right: $grid-gutter-height/4;
      }
    }
  }
  &.block-text-tiles {
    padding-bottom: 0;
    .tiles {
      justify-content: center;
      .tile {
        margin-top: $grid-gutter-width;
        margin-bottom: 0;
        text-align: left;
        h3 {
          text-align: center;
        }
        article {
          @extend .bg-white;
          text-align: left;
          height: 100%;
          border-radius: 10px;
          padding: $grid-gutter-width $grid-gutter-height;
          ul {
            margin-bottom: 0;
          }
        }
      }
    }
    .text-tiles-title {
      text-align: center;
    }
  }
  &.block-vidoes {
    padding: 0;
    margin-bottom: $grid-gutter-width;
    text-align: center;
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*2;
    }
    .video-tile {
      margin-bottom: $grid-gutter-width;
    }
    .video-description {
      padding: $grid-gutter-height/2 $grid-gutter-height 0;
      font-size: $font-size-sm;
    }
  }
  &.block-related {
    padding-top: 0;
    h1 {
      text-align: center;
    }
    .article-tiles {
      .tile {
        @include media-breakpoint-only(md) {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
.block-cta {
  @extend .bg-white;
  margin: 0 0 $grid-gutter-height 0;
  @include media-breakpoint-between(sm,md) {
    max-width: 480px;
    margin: 0 auto $grid-gutter-width auto;
  }
  @include media-breakpoint-up(lg) {
    margin: 0 0 $grid-gutter-height*3 0;
    &>.row {
      align-items: center;
    }
  }
  figure {
    @extend .bg-gray;
    margin: 0;
    a {
      img {
        @extend .img-fluid;
        @include transition($link-transition);
      }
      @include hover-focus {
        img {
          opacity: .95;
        }
      }
    }
  }
  .details {
    text-align: center;
    article {
      padding: $grid-gutter-height $grid-gutter-width $grid-gutter-height*2;
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-height*1.5 $grid-gutter-height*2 $grid-gutter-height*1.5 $grid-gutter-height/2;
      }
      h1 {
        margin-bottom: $grid-gutter-height;
        @include media-breakpoint-up(xl) {
          margin-bottom: $grid-gutter-height*1.5;
        }
        a {
          color: $body-color;
          text-decoration: none;
          font-weight: $font-weight-base;
          @include hover-focus {
            color: $link-color;
          }
        }
      }
    }
  }
  &:nth-child(odd) {
    @include media-breakpoint-up(lg) {
      &>.row {
        flex-direction: row-reverse;
        .details {
          article {
            padding-right: $grid-gutter-height/2;
            padding-left:  $grid-gutter-height*2;
          }
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.block-members {
  margin-bottom: $grid-gutter-height*2;
  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-height*3;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-height*4;
  }
  .team-anchors {
    ul {
      @extend .list-normalize;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 $grid-gutter-height/2;
      }
    }
  }
  .info {
    text-align: center;
    padding-top: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width*2;
    }
    h1 {
      margin-bottom: $grid-gutter-height;
      @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-height*1.5;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-height*2;
      }
    }
    .btn {
      margin: $grid-gutter-height auto;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-height*1.5;
      }
    }
  }
  .individuals {
    .individual {
      font-size: $font-size-sm;
      margin-bottom: $grid-gutter-height/2;
      position: relative;
      padding-left: 100px;
      @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-height;
        font-size: $font-size-sm;
        padding-left: 140px;
      }
      figure {
        position: absolute;
        left: $grid-gutter-width/2;
        top: $grid-gutter-height/4;
        width: 70px;
        height: 70px;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
          width: 100px;
          height: 100px;
        }
        img {
          border-radius: 50%;
          width: 100%;

        }
      }
      .details {
        min-height: 80px;
        @include media-breakpoint-up(md) {
          min-height: 120px;
        }
      }
      .img {
        @include media-breakpoint-down(xs) {
          padding-right: 0;
        }
        img {
          margin: 0 auto 1rem;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          @include media-breakpoint-up(md) {
            width: 100px;
            height: 100px;
          }
        }
      }
      .details {
        h3 {
          margin-bottom: $grid-gutter-height/2.2;
          a {
            font-weight: $font-weight-semi;
            color: $body-color;
            text-decoration: none;
            @include hover-focus {
              color: $link-color;
            }
          }
        }
        h4 {
          margin-bottom: $grid-gutter-height/2.2;
        }
      }
      &.hidden {
        display: none;
      }
    }
    &.large-format {
      .individual {
        .details {
          p,section {
            margin-bottom: .5em;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        .individual {
          padding-left: 180px;
          margin-bottom: $grid-gutter-width;
          .btn-xs {
            i {
              margin-left: .5em;
            }
          }
          figure {
            width: 140px;
          }
          .details {
            min-height: 180px;
          }
        }
      }
    }
    &:not(.large-format) {
      @include media-breakpoint-up(md) {
        &>.row {
          justify-content: center;
        }
        .individual {
          text-align: center;
          padding-left: $grid-gutter-width/2;
          figure {
            position: relative;
            left: auto;
            top: auto;
            margin: 0 auto 1rem;
          }
        }
      }
    }
  }
}
.block-statistic {
  margin-bottom: $grid-gutter-height*2;
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-height*3;
  }
  article {
    @extend .bg-secondary;
    text-align: center;
    border-radius: $grid-gutter-height/2;
    padding: $grid-gutter-height;
    line-height: 1.25;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-height*2;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-height*2 $grid-gutter-height*2.5;
      text-align: inherit;
      .row {
        align-items: center;
      }
    }
    p {
      font-size: $font-size-lg;
      font-weight: $font-weight-med;
      margin-bottom: $grid-gutter-height*.75;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    .btn {
      @include media-breakpoint-up(lg) {
        float: right;
      }
    }
  }
}
.block-testimonials {
  @extend .bg-gray;
  padding: $grid-gutter-height*2 0;
  margin-bottom: $grid-gutter-height*2;
  text-align: center;
  font: $font-size-md $font-family-serif;
  line-height: 1.5;
  .block-title {
    margin-bottom: $grid-gutter-height*1.5;
  }
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-height*2 0 $grid-gutter-height*3;
    font-size: $font-size-lg;
  }
  .attribution {
    @extend .condensed-caps;
    font-size: $font-size-base;
    margin-bottom: 0;
    line-height: 1.2;
  }
}
.block-donor-profiles {
  padding: 0;
  h1 {
    text-align: center;
    margin-bottom: 1em;
  }
}
