// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
.form-control {
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
.label-style {
  @extend .condensed-caps;
  font-size: $font-size-sm;
}
.subscribe-form {
  &.form-inline {
    justify-content: center;
    .form-group {
      label {
        font-size: $font-size-h3;
        font-weight: $font-weight-bold;
        @include media-breakpoint-only(md) {
//           font-size: $font-size-h4;
        }
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        flex-direction: column;
        flex: 100%;
        label,input,button {
          width: 100%;
          margin-bottom: $grid-gutter-width/2;
        }
        button {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-up(md) {
        button {
          margin-left: $grid-gutter-height;
        }
        input {
          margin-left: $grid-gutter-width;
        }
      }
      @include media-breakpoint-up(lg) {
        button {
          margin-left: $grid-gutter-width;
        }
        input {
          margin-left: $grid-gutter-width*3;
        }
      }
    }
  }
}
.jetpack_subscription_widget {
  form {
    #subscribe-text,#subscribe-email,#subscribe-submit {
      display: inline-block;
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: $grid-gutter-width/2;
      }
    }
    #subscribe-text {
      h3 {
        font-weight: $font-weight-bold;
      }
    }
    #subscribe-email {
      input {
        @extend .form-control;
      }
      @include media-breakpoint-up(md) {
        margin-left: $grid-gutter-width;
      }
      @include media-breakpoint-up(lg) {
        margin-left: $grid-gutter-width*3;
      }
    }
    #subscribe-submit {
      button {
        @extend .btn;
      }
      @include media-breakpoint-up(md) {
        margin-left: $grid-gutter-height;
      }
      @include media-breakpoint-up(lg) {
        margin-left: $grid-gutter-width;
      }
    }
  }
}
#gform_wrapper_1 {
  @extend .bg-white;
  padding: $grid-gutter-height $grid-gutter-width;
  border-radius: 10px;
  margin: $grid-gutter-width 0;
  .top_label .gfield_label,legend.gfield_label {
    @extend .label-style;
    margin-bottom: 0;
  }
  .gfield_required {
    color: $brand-secondary;
  }
  .field_sublabel_below .ginput_complex.ginput_container label,
  .field_sublabel_below div[class*=gfield_time_].ginput_container label {
    margin-bottom: 0;
    font-size: $font-size-xs;
    color: $gray-dark;
  }
  ul.gform_fields li.gfield span.name_first,
  ul.gform_fields li.gfield span.name_last,
  ul.gform_fields li.gfield span.name_middle {
    padding-top: 0;
  }

  .top_label div.ginput_complex.ginput_container.gf_name_has_1,
  .top_label div.ginput_complex.ginput_container.gf_name_has_2,
  .top_label div.ginput_complex.ginput_container.gf_name_has_3,
  div.ginput_container {
    margin-top: 0;
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),textarea {
    @extend .form-control;
  }
  ul.gform_fields {
    li.gfield {
      padding-right: 0;
      &#field_1_6 {
        @media (min-width: 641px) {
          width: 50%;
          display: inline-block;
          padding-right: 8px;
        }
      }
      &#field_1_8 {
        @media (min-width: 641px) {
          width: 50%;
          display: inline-block;
          padding-left: 8px;
          padding-right: 2px;
        }
      }
    }
  }
  .gform_footer {
//     text-align: center;
  }
  .gform_footer input.button,
  .gform_footer input[type=submit],
  .gform_page_footer input.button,
  .gform_page_footer input[type=submit] {
    @extend .btn;
  }
  .gfield_checkbox li input,
  .gfield_checkbox li input[type=checkbox],
  .gfield_radio li input[type=radio] {
    margin-top: 0;
    margin-right: 8px;
  }
  &.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: 100% !important;
  }
  li.gfield.gfield_error,
  li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: transparent;
    margin-bottom: 0 !important;
    border-top: none;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;
    .gfield_label {
      color: $brand-secondary;
    }
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),textarea {
      border-color: rgba($brand-secondary,.5);
    }
    div.ginput_complex.ginput_container label,
    ul.gfield_checkbox,
    ul.gfield_radio {
      color: $brand-secondary;
    }
  }
  .validation_message {
    color: $brand-secondary;
    padding-top: 4px;
    font-weight: $font-weight-base;
    font-style: italic;
  }
  div.validation_error {
    @extend .bg-secondary;
    border: none;
    border-radius: 10px;
  }
}
